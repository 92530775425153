const state = () => ({
  isOpen: false
})

// getters
const getters = {
  isOpen: ({ isOpen }) => isOpen
}

// actions
const actions = {
  open({ commit }) {
    commit('SET_IS_OPEN', true)
  },

  close({ commit }) {
    commit('SET_IS_OPEN', false)
  }
}

// mutations
const mutations = {
  SET_IS_OPEN(state, isOpen) {
    state.isOpen = isOpen
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
