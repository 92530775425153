const state = () => ({
  isOpen: false,
  error: false,
  message: ''
})

// getters
const getters = {
  isOpen: ({ isOpen }) => isOpen,

  message: ({ message }) => message,

  error: ({ error }) => error
}

// actions
const actions = {
  open({ commit }, { message, error }) {
    commit('SET_ERROR', error)
    commit('SET_MESSAGE', message)
    commit('SET_IS_OPEN', true)
  },

  close({ commit }) {
    commit('SET_IS_OPEN', false)
  }
}

// mutations
const mutations = {
  SET_IS_OPEN(state, isOpen) {
    state.isOpen = isOpen
  },

  SET_ERROR(state, error) {
    state.error = error
  },

  SET_MESSAGE(state, message) {
    state.message = message
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
