import { createApp, Vue } from 'vue'
import App from './App.vue'
import store from '../store'
import VueYandexMetrika from 'vue-v3-yandex-metrika'
import VueFullPage from 'vue-fullpage.js'

const app = createApp(App)

app.use(store)
app.use(VueYandexMetrika, {
  id: 92575381,
  env: 'production',
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true
  }
})
app.use(VueFullPage)
app.mount('#app')
