import { createStore } from 'vuex'
import form from './modules/form'
import alert from './modules/alert'

export default createStore({
  modules: {
    form,
    alert
  }
})
